import { localStorageHelper } from 'app/shared/helpers';
import { httpMethod, httpRequest, redirectToLandingPage } from '../_httpClient';
import { ServiceResponse } from '../_httpClient/models/http-client.model';
import {
    ExistenceCheckRequest,
    ExistenceCheckResponse,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    LoginRequest,
    LoginResponse,
    LoginWithTokenRequest,
    LoginWithTokenResponse,
    RegisterRequest,
    RegisterResponse,
    ResetPasswordRequest,
    SendCodeRequest,
    ValidateEmailAddressRequest,
    ValidateEmailAddressResponse,
    VerifyCodeRequest,
    VerifyCodeResponse,
} from './models';
import { LogoutRequest, LogoutResponse } from './models/logout.model';
import { getAppVersion } from 'app/shared/helpers/settings.helper';

export const logIn = (request: LoginRequest): ServiceResponse<LoginResponse> => {
    const endpointUrl = '/Authentication/login';

    const headers = {
        'Content-Type': 'application/json',
    };

    request.appVersion = getAppVersion();

    return httpRequest<LoginRequest, LoginResponse>(
        endpointUrl,
        httpMethod.Post,
        headers,
        request
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        const { success } = response.data;

        if (success === true) {
            if (response.data.data.authenticationToken) {
                const { token } = response.data.data.authenticationToken;
                setAuthToken(token);
            }
        }

        return response;
    });
};

export const setAuthToken = (token: string): void => {
    localStorageHelper.set('@nc_token', token);
    localStorageHelper.set('@nc_token_last_action', Date.now());
};

export const removeAuthToken = (): void => {
    localStorageHelper.remove('@nc_token');
    localStorageHelper.remove('@nc_token_last_action');
};

export const loginWithToken = (
    request: LoginWithTokenRequest
): ServiceResponse<LoginWithTokenResponse> => {
    const endpointUrl = '/Authentication/LoginWithToken';
    const headers = {
        'Content-Type': 'application/json',
    };

    request.appVersion = getAppVersion();

    return httpRequest<LoginWithTokenRequest, LoginWithTokenResponse>(
        endpointUrl,
        httpMethod.Post,
        headers,
        request
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        const { success } = response.data;

        if (success === true) {
            const { token } = response.data.data.authenticationToken;
            setAuthToken(token);
        }

        return response;
    });
};

export const logOut = (): ServiceResponse<LogoutResponse> => {
    const endpointUrl = '/Authentication/logout';

    return httpRequest<LogoutRequest, LogoutResponse>(endpointUrl, httpMethod.Post)
        .then((response) => {
            if ('isAxiosError' in response) return null;

            return response;
        })
        .finally(() => {
            removeAuthToken();
            redirectToLandingPage();
        });
};

export const forgotPassword = (
    request: ForgotPasswordRequest
): ServiceResponse<ForgotPasswordResponse> => {
    const endpointUrl = '/Authentication/forgotPassword';

    const headers = {
        'Content-Type': 'application/json',
    };

    const payload = request;

    return httpRequest<ForgotPasswordRequest, ForgotPasswordResponse>(
        endpointUrl,
        httpMethod.Post,
        headers,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const register = (request: RegisterRequest): ServiceResponse<RegisterResponse> => {
    const endpointUrl = '/Authentication/register';

    const headers = {
        'Content-Type': 'application/json',
    };

    const payload = request;

    return httpRequest<RegisterRequest, RegisterResponse>(
        endpointUrl,
        httpMethod.Post,
        headers,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        const { success } = response.data;

        if (success === true) {
            const { token } = response.data.data.authenticationToken;

            setAuthToken(token);
        }

        return response;
    });
};

export const validateEmailAddress = (
    request: ValidateEmailAddressRequest
): ServiceResponse<ValidateEmailAddressResponse> => {
    const endpointUrl = '/authentication/validateEmailAddress';
    const payload = request;

    return httpRequest<ValidateEmailAddressRequest, ValidateEmailAddressResponse>(
        endpointUrl,
        httpMethod.Post,
        undefined,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const resetPassword = (request: ResetPasswordRequest): ServiceResponse<string> => {
    const endpointUrl = '/authentication/resetPassword';
    const payload = request;

    return httpRequest<ResetPasswordRequest, string>(
        endpointUrl,
        httpMethod.Post,
        undefined,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const existenceCheck = (
    request: ExistenceCheckRequest
): ServiceResponse<ExistenceCheckResponse> => {
    const endpointUrl = '/authentication/existenceCheck';
    const payload = request;

    return httpRequest<ExistenceCheckRequest, ExistenceCheckResponse>(
        endpointUrl,
        httpMethod.Post,
        undefined,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const sendCode = (request: SendCodeRequest): ServiceResponse<boolean> => {
    const endpointUrl = '/authentication/verificationCodes';
    const payload = request;

    return httpRequest<SendCodeRequest, boolean>(
        endpointUrl,
        httpMethod.Post,
        undefined,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const verifyCode = (request: VerifyCodeRequest): ServiceResponse<VerifyCodeResponse> => {
    const endpointUrl = '/authentication/verifyCode';
    const payload = request;

    return httpRequest<SendCodeRequest, VerifyCodeResponse>(
        endpointUrl,
        httpMethod.Post,
        undefined,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};
